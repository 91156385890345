import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./main.scss";
import Dashboard from "./dashboard/index";
import Inquery from "./dashboard/inquery";
const Main: React.FC<any> = () => {

    return (
        <Router>
            <Routes>
                <Route element={<Inquery />} path='/account/:id' />
                <Route element={<Inquery />} path='/' />

                {/* <Route element={<Inquery />} path='/account' /> */}
                {/* <Route element={<Dashboard />} path='/' /> */}
            </Routes>
        </Router>
    );
};

export default Main;