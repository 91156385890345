export const api = 'https://api-xpanel.conectbash.info';

export type ApiResult<T> = {
    value: T,
    isSuccess: boolean,
    status: number,
    message: string,
    errors: ApiError[]
}
export type ApiError = {
    field: string,
}
export const endpoints = {
    dashboard: {
        inquery: 'account/'
    }
}


export function responseValidator(status: number) {
    return status >= 200 && status < 300;
}
