import React, { useCallback, useEffect, useState } from 'react';
import './style.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Avatar, Button, Card, Col, Input, Layout, Progress, QRCode, Row, Skeleton, Spin, Statistic } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content, Header } from 'antd/es/layout/layout';
import Container from 'components/container';
import { setuid } from 'process';
import { post } from 'utils/api';
import { endpoints } from 'data/api';
import { SearchResultAccount } from 'domains/account/Account';
import { toast } from 'react-toastify';
import { get } from 'utils/api';
import { en2Fa, isGuid } from 'utils/strings';
import Meta from 'antd/es/card/Meta';
import { EditOutlined, EllipsisOutlined, CopyOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { remainedDays, toPersianDate } from 'utils/date';
import axios from 'axios';

const Inquery: React.FC<any> = () => {
    const [uid, setUid] = useState<string>('');
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            setPageLoading(true);
            search(id);
            setPageLoading(false);
        }
    }, []);
    const [account, setAccount] = useState<SearchResultAccount>();
    const getUsagePercent = (account: SearchResultAccount) => {
        var percent = ((account.download + account.upload) / account.service.traffic) * 100;
        return Math.floor(percent);
    }

    const tabList = [
        {
            key: 'account',
            tab: 'اطلاعات اشتراک',
        },
        {
            key: 'help',
            tab: 'راهنمای اتصال',
        },
    ];
    const contentList: Record<string, React.ReactNode> = {
        account:
            account && <div className="account-info">
                <div className="account-card">

                    <Skeleton className='sevice-info' loading={loading} avatar active>
                        <Meta
                            // avatar={<img src="/images/logo.jpeg" />}
                            title={account.service.title}
                            description={
                                <div className='account-state'>
                                    <span style={{ "color": (account.state.id == 1 ? "green" : "red") }}>{account.state.title}</span>
                                    {
                                        account.state.id == 1 && <span className='state-ripple'></span>
                                    }
                                </div>
                            }
                        />

                    </Skeleton>

                    <div className="usage-info">
                        <Statistic
                            title="دانلود"
                            value={en2Fa(account.download.toString())}
                            precision={2}
                            prefix={<ArrowDownOutlined />}
                            suffix="گیگابایت"
                        />
                        <Statistic
                            title="آپلود"
                            value={en2Fa(account.upload.toString())}
                            precision={2}
                            prefix={<ArrowUpOutlined />}
                            suffix="گیگابایت"
                        />
                        {
                            account.service.traffic != 0 && <Progress strokeColor={{ '0%': '#ffccc7', '50%': '#ffe58f', '100%': '#87d068' }} strokeLinecap="butt" type="circle"
                                percent={getUsagePercent(account)} />
                        }

                    </div>
                    <div className="duration-info">

                        <span>
                            تاریخ انقضا: {toPersianDate(account.expiresOn)}
                        </span>
                    </div>
                    <div className="conncetion-info">
                        <div className="right-side">
                            <QRCode
                                errorLevel="H"
                                value={account.url}
                            />
                            {/* <Button >
                                    گزارش اختلال
                                </Button> */}
                        </div>
                        <Alert
                            message={<>
                                <span>{account.url}</span>
                                <Button onClick={() => copyToClipboard(account.url)} type="default" >
                                    <CopyOutlined key="copy" />  کپی لینک
                                </Button>
                            </>}
                            type="warning"
                            closable={false}
                        />
                    </div>

                </div>
            </div>,
        help: <p>بزودی...</p>,
    };

    const search = (id: string) => {
        if (id !== '' && id.length > 20) {
            setLoading(true)
            get(endpoints.dashboard.inquery + `${id}`).then((response: any) => {
                console.log(response);
                console.log(response.data);
                if (response.data.isSuccess) {
                    if (response.data.data) {
                        setAccount(response.data.data)
                    }
                    else toast.error(response.data.message);


                }
                else toast.error(response.data.message);
                setLoading(false);
            });
        }
        else toast.error('لطفا یک شناسه اشتراک معتبر وارد کنید.')
    }
    const copyToClipboard = useCallback(
        async (text: string) => {
            await navigator.clipboard.writeText(text);
            toast.success('لینک اشتراک با موفقیت کپی شد.');
        },
        [],

    );
    const [activeTab, setActiveTab] = useState<string>('account');
    const onTabChange = (key: string) => {
        setActiveTab(key);
    };


    return (
        pageLoading === true ? <Spin tip="در حال جستجوی اشتراک.." spinning={pageLoading || loading} /> :
            <Container>
                {
                    id !== '' && account ? <>
                        <Card
                            className='main-tabs-card'
                            style={{ width: '100%' }}
                            extra={<a onClick={() => setAccount(undefined)}>بازگشت به استعلام</a>}
                            tabList={tabList}
                            activeTabKey={activeTab}
                            onTabChange={onTabChange}
                        >
                            {contentList[activeTab]}
                        </Card>

                    </> :
                        <>
                            <Input
                                placeholder='شناسه اشتراک'
                                value={uid}
                                onChange={(e) => setUid(e.target.value)}
                            />
                            <Button
                                loading={loading}
                                disabled={loading}
                                className='inquery-button'
                                type='primary'
                                onClick={() => search(uid)}
                            >
                                استعلام
                            </Button></>
                }
            </Container>
    )
};

export default Inquery;