import React, { useEffect, useState } from 'react';
import './style.scss'


const Container: React.FC<any> = (props) => {
    return (
        <div className='cloud-container'>
            {props.children}
        </div>
    )
};

export default Container;