import React from 'react';
import './styles/index.scss'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'swiper/css';
import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import Main from './pages/main';

function App() {

  // const rootReducer = combineReducers({
  //   cart: cartReducer,
  // });

  // const store = createStore(rootReducer);

  return (
    <>
      <ConfigProvider locale={frFR}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          theme='light'
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Main />
      </ConfigProvider>
    </>
  );
}

export default App;
