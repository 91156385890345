import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { api } from 'data/api';
import { resolve } from 'path';
import moment from "moment";


interface RequestOptions extends AxiosRequestConfig { }

export function post(url: string, body: any, fromForm: boolean = false): Promise<{ data: any, status: number | null }> {
    let status: number | null = null;

    return new Promise((resolve, reject) => {
        const header: { [key: string]: string } = fromForm === false ? { "Content-Type": "application/json" } : {};
        let token = localStorage.getItem("token");
        header.Authorization = "Bearer " + token;

        fetch(api + '/' + url, {
            method: "POST",
            headers: header,
            body: fromForm === true ? body : JSON.stringify(body),
        })
            .then(function (response) {
                if (response.status === 403 || response.status === 401) {
                    window.open("/login", "_self");
                }
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                resolve({ data, status });
            })
            .catch((err) => {
                resolve({ data: null, status });
            });
    });
}


export function postViaAuth(url: string, body: any, fromForm: boolean = false): Promise<{ data: any, status: number | null }> {
    let status: number | null = null;

    return new Promise((resolve, reject) => {
        const header: { [key: string]: string } = { "Content-Type": "application/json" };
        let token = localStorage.getItem("token");
        header.Authorization = "Bearer " + token;


        fetch(api + '/' + url, {
            method: "POST",
            mode: 'cors', // Enable CORS
            headers: header,
            body: fromForm === true ? body : JSON.stringify(body),
        })
            .then(function (response) {
                if (response.status === 403 || response.status === 401) {
                    window.open("/login", "_self");
                }
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                resolve({ data, status });
            })
            .catch((err) => {
                resolve({ data: null, status });
            });
    });
}
export function get(url: string): Promise<{ data: any, status: number | null }> {
    let status: number | null = null;

    return new Promise((resolve, reject) => {
        const header: { [key: string]: string } = { "Content-Type": "application/json" };
        fetch(api + '/' + url, {
            method: "GET",
            mode: 'cors', // Enable CORS
            headers: header,
        })
            .then(function (response) {
                if (response.status === 403 || response.status === 401) {
                    window.open("/login", "_self");
                }
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                resolve({ data, status });
            })
            .catch((err) => {
                resolve({ data: null, status });
            });
    });
}
export function getViaAuth(url: string): Promise<{ data: any, status: number | null }> {
    let status: number | null = null;

    return new Promise((resolve, reject) => {
        const header: { [key: string]: string } = { "Content-Type": "application/json" };
        let token = localStorage.getItem("token");
        header.Authorization = "Bearer " + token;

        fetch(api + '/' + url, {
            method: "GET",
            mode: 'cors', // Enable CORS
            headers: header,
        })
            .then(function (response) {
                if (response.status === 403 || response.status === 401) {
                    window.open("/login", "_self");
                }
                status = response.status;
                return response.json();
            })
            .then(function (data) {
                resolve({ data, status });
            })
            .catch((err) => {
                resolve({ data: null, status });
            });
    });
}
export const genericRepository = {
    get: async<T>(endpoint: string, options: RequestOptions = {
        headers: {

        }
    }): Promise<T> => {
        try {
            const response: AxiosResponse<T> = await axios.get(`${api}/${endpoint}`, options);
            return response.data;
        } catch (error: any) {
            throw new Error(error.message);
        }
    },
    put: async<T>(endpoint: string, data: any = {}, options: RequestOptions = {}): Promise<T> => {
        try {
            const response: AxiosResponse<T> = await axios.put(`${api}/${endpoint}`, data, options);
            return response.data;
        } catch (error: any) {
            throw new Error(error.message);
        }
    },

    delete: async<T>(endpoint: string, options: RequestOptions = {}): Promise<T> => {
        try {
            const response: AxiosResponse<T> = await axios.delete(`${api}/${endpoint}`, options);
            return response.data;
        } catch (error: any) {
            throw new Error(error.message);
        }
    },
};