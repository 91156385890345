import moment from 'jalali-moment';
import { en2Fa } from './strings';

export const toPersianDate = (date: Date) => {
    const result = en2Fa(moment(date.toString().split('T')[0]).locale('fa').format('jDD MMMM YYYY'));
    return result;
}
export const remainedDays = (date: Date) => {
    const currentDate = new Date();
    const diffInMilliseconds = Math.abs(currentDate.getTime() - date.getTime());
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    return diffInDays.toString();
}